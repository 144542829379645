<template>
  <div class="px-md-5 px-xs-0 -platform-features">
    <div class="col-12 -platform-features-intro d-flex flex-column align-items-center">
      <label class="-txt-ws d-block" id="wsLabel">white<b>Strings</b></label>
      <div class="col-10 col-lg-8 -features-description">
        A School Automation Platform designed in a hyperconnected environment that provides a complete solution fo the school's daily operations by eliminationg tedious work processes and operates as one platform across different departments.
      </div>
      <div id="imgFeaturesIntroImageContainer" class="w-100">
        <img src="@/assets/graphics/v2/features_intro_image.svg" id="imgFeaturesIntroImage" class="-features-intro-image" style="transition: width 1.2s ease-out, opacity 1s ease-out;"/>
      </div>
    </div>

    <div class="row -z-index-2 position-relative d-none d-md-flex justify-content-center align-items-center mb-3">
      <div class="col-8">
        <div class="row py-2 -plaform-features-title position-sticky" id="platformFeaturesTitle" style="top: 100px;">
          <div class="col-6"><h3 class="fw-bolder">School Management</h3></div>
          <div class="col-6"><h3 class="fw-bolder">Learning Management</h3></div>
        </div>
        <div class="row py-2 -plaform-features-item">
          <div class="col-6 text-start fw-bold">Online Enrollment</div>
          <div class="col-6 text-end fw-bold">Campus & Class Feed</div>
        </div>
        <div class="row py-2 -plaform-features-item">
          <div class="col-6 text-start fw-bold">Schedule Management</div>
          <div class="col-6 text-end fw-bold">Subject-based Online Classrooms</div>
        </div>
        <div class="row py-2 -plaform-features-item">
          <div class="col-6 text-start fw-bold">Subject & Curriculum Management</div>
          <div class="col-6 text-end fw-bold">Student Progress Tracking</div>
        </div>
        <div class="row py-2 -plaform-features-item">
          <div class="col-6 text-start fw-bold">Cashiering System</div>
          <div class="col-6 text-end fw-bold">Dynamic Online Workbooks</div>
        </div>
        <div class="row py-2 -plaform-features-item">
          <div class="col-6 text-start fw-bold">School E-Commerce</div>
          <div class="col-6 text-end fw-bold">Document Sharing</div>
        </div>
        <div class="row py-2 -plaform-features-item">
          <div class="col-6 text-start fw-bold">Library System</div>
          <div class="col-6 text-end fw-bold">Online Grading System</div>
        </div>
        <div class="row py-2 -plaform-features-item">
          <div class="col-6 text-start fw-bold">Financial Management</div>
          <div class="col-6 text-end fw-bold">Online Attendance</div>
        </div>
        <div class="row py-2 -plaform-features-item">
          <div class="col-6 text-start fw-bold">Student & Employee Data Management</div>
          <div class="col-6 text-end fw-bold">Automated Payment Reminders</div>
        </div>
        <div class="row py-2 -plaform-features-item">
          <div class="col-6 text-start fw-bold">Guidance System</div>
          <div class="col-6 text-end fw-bold">Email, SMS & in-App Notifications</div>
        </div>
        <div class="row py-2 -plaform-features-item">
          <div class="col-6 text-start fw-bold">Clinic System</div>
          <div class="col-6 text-end fw-bold">Online Payment Ready</div>
        </div>
        <div class="row py-2 -plaform-features-item">
          <div class="col-6 text-start fw-bold">Smart Id System</div>
          <div class="col-6 text-end fw-bold">Anti-Cheating System</div>
        </div>
        <div class="row py-2 -plaform-features-item">
          <div class="col-6 text-start fw-bold">Data Analytics & Dashboard</div>
          <div class="col-6 text-end fw-bold">Online Assessments & Examinations</div>
        </div>
        <div class="row py-2 -plaform-features-item">
          <div class="col-6 text-start fw-bold">Audit Trail</div>
          <div class="col-6 text-end fw-bold">Student Analysis</div>
        </div>
        <div class="row py-2 -plaform-features-item">
          <div class="col-6 text-start fw-bold">and more...</div>
          <div class="col-6 text-end fw-bold">and more...</div>
        </div>
      </div>
    </div>

    <div class="row justify-content-center position-relative -z-index-2 d-block d-md-none" id="wsFeaturesContainer">
      <div class="col-12 col-md-4 text-center">
        <h3 class="fw-bolder">School Management</h3>
        <div class="col-12">
          <div class="d-flex flex-column justify-content-center align-items-center justify-content-md-start align-items-md-start -sms-features-list">
            <div class="-sms-features-item">Online Enrollment</div>
            <div class="-sms-features-item">Schedule Management</div>
            <div class="-sms-features-item">Subject & Curriculum Management</div>
            <div class="-sms-features-item">Cashiering System</div>
            <div class="-sms-features-item">School E-Commerce</div>
            <div class="-sms-features-item">Library System</div>
            <div class="-sms-features-item">Financial Management</div>
            <div class="-sms-features-item">Student & Employee Data Management</div>
            <div class="-sms-features-item">Guidance System</div>
            <div class="-sms-features-item">Clinic System</div>
            <div class="-sms-features-item">Smart Id System</div>
            <div class="-sms-features-item">Data Analytics & Dashboard</div>
            <div class="-sms-features-item">Audit Trail</div>
            <div class="-sms-features-item">and more...</div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-4 text-center pt-4 pt-sm-0">
        <h3 class="fw-bolder">Learning Management</h3>
        <div class="col-12">
          <div class="d-flex flex-column justify-content-center align-items-center justify-content-md-end align-items-md-end -lms-features-list">
            <div class="-lms-features-item">Campus & Class Feed</div>
            <div class="-lms-features-item">Subject-based Online Classrooms</div>
            <div class="-lms-features-item">Student Progress Tracking</div>
            <div class="-lms-features-item">Dynamic Online Workbooks</div>
            <div class="-lms-features-item">Document Sharing</div>
            <div class="-lms-features-item">Online Grading System</div>
            <div class="-lms-features-item">Online Attendance</div>
            <div class="-lms-features-item">Automated Payment Reminders</div>
            <div class="-lms-features-item">Email, SMS & in-App Notifications</div>
            <div class="-lms-features-item">Online Payment Ready</div>
            <div class="-lms-features-item">Anti-Cheating System</div>
            <div class="-lms-features-item">Online Assessments & Examinations</div>
            <div class="-lms-features-item">Student Analysis</div>
            <div class="-lms-features-item">and more...</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlatformFeatures',
  data () {
    return {
      percentage: 0,
      laptopImageWidth: 60
    }
  },
  mounted () {
    const element = document.getElementById('wsLabel');

    window.addEventListener('scroll', () => {
      const viewportHeight = window.innerHeight;
      const scrollTop = window.scrollY;
      const elementOffsetTop = element.offsetTop;
      const elementHeight = element.offsetHeight;

      if (window.innerWidth > 480) {
        const distance = (scrollTop + viewportHeight) - elementOffsetTop;
        this.percentage = Math.round(distance / ((viewportHeight + elementHeight) / 100));
      }

      // const platformFeaturesTitleTopOffset =  document.getElementById('platformFeaturesTitle').getBoundingClientRect().top
      const platformFeatures = document.querySelectorAll('.-plaform-features-item')

      for (let platformFeature of platformFeatures) {

        const platformFeatureTopOffset = platformFeature.getBoundingClientRect().top
        let opacity = 1

        if (platformFeatureTopOffset < 140) {
          opacity = 0
        } else if (platformFeatureTopOffset < 160) {
          opacity = 0.2
        } 
        platformFeature.style.opacity = opacity
      }
    })
  },
  watch: {
    percentage (newValue, prevValue) {
      if (newValue > prevValue) {
        
        if (newValue < 100) {
          if (this.laptopImageWidth < 100) {
            document.getElementById('imgFeaturesIntroImage').style.width = `${this.laptopImageWidth}%`
            
            this.laptopImageWidth += 12
          } else {
            this.laptopImageWidth = 100
            document.getElementById('imgFeaturesIntroImage').style.width = `${this.laptopImageWidth}%`
            document.getElementById('imgFeaturesIntroImageContainer').style.padding = '5em 0'

            document.getElementById('imgFeaturesIntroImageContainer').style.height = '500px'
            document.getElementById('imgFeaturesIntroImage').style.top = '60%'
            document.getElementById('imgFeaturesIntroImage').style.left = '51%'
            document.getElementById('imgFeaturesIntroImage').style.transform = 'translate(-50%, -50%)'
            document.getElementById('imgFeaturesIntroImage').style.position = 'fixed'
            document.getElementById('imgFeaturesIntroImage').style.zIndex = 1;
            document.getElementById('imgFeaturesIntroImage').style.opacity = 0.1;
          }
        } else {
            this.laptopImageWidth = 100
            document.getElementById('imgFeaturesIntroImage').style.width = `${this.laptopImageWidth}%`
            document.getElementById('imgFeaturesIntroImageContainer').style.padding = '5em 0'

            document.getElementById('imgFeaturesIntroImageContainer').style.height = '500px'
            document.getElementById('imgFeaturesIntroImage').style.top = '60%'
            document.getElementById('imgFeaturesIntroImage').style.left = '51%'
            document.getElementById('imgFeaturesIntroImage').style.transform = 'translate(-50%, -50%)'
            document.getElementById('imgFeaturesIntroImage').style.position = 'fixed'
            document.getElementById('imgFeaturesIntroImage').style.zIndex = 1;
            document.getElementById('imgFeaturesIntroImage').style.opacity = 0.1;
          }
      } else {
        if (newValue > 79) {
          if (newValue <= 100) {
            this.laptopImageWidth = 90

            if (this.laptopImageWidth > 60 ) {
              this.laptopImageWidth -= 12
              document.getElementById('imgFeaturesIntroImage').style.width = `${this.laptopImageWidth}%`
            } else {
              this.laptopImageWidth = 60
              document.getElementById('imgFeaturesIntroImage').style.width = `${this.laptopImageWidth}%`
            }

            document.getElementById('imgFeaturesIntroImage').style.opacity = 1;
            document.getElementById('imgFeaturesIntroImage').style.width = `${this.laptopImageWidth}%`
            document.getElementById('imgFeaturesIntroImage').style.top = 'unset'
            document.getElementById('imgFeaturesIntroImage').style.left = 'unset'
            document.getElementById('imgFeaturesIntroImage').style.transform = ''
            document.getElementById('imgFeaturesIntroImage').style.position = 'static'
            document.getElementById('imgFeaturesIntroImageContainer').style.padding = '5em 0'
            document.getElementById('imgFeaturesIntroImageContainer').style.height = 'unset'
          }
        } else {
          document.getElementById('imgFeaturesIntroImage').style.opacity = 1;
          document.getElementById('imgFeaturesIntroImage').style.width = `60%`
          document.getElementById('imgFeaturesIntroImage').style.top = 'unset'
          document.getElementById('imgFeaturesIntroImage').style.left = 'unset'
          document.getElementById('imgFeaturesIntroImage').style.transform = ''
          document.getElementById('imgFeaturesIntroImage').style.position = 'static'
          document.getElementById('imgFeaturesIntroImageContainer').style.padding = '0'
          document.getElementById('imgFeaturesIntroImageContainer').style.height = 'unset'
        }
      }
    },
  }
}
</script>