<template>
  <div class="pt-5">
    <WebsiteIntro />
    <MobileBanner />
    <CompaniesWeWorkWith />
    <FrequentlyAskQuestions />
  </div>
</template>

<script>
import WebsiteIntro from '@/components/v2/home/WebsiteIntro'
import MobileBanner from '@/components/v2/home/MobileBanner'
import CompaniesWeWorkWith from '@/components/v2/home/CompaniesWeWorkWith'
import FrequentlyAskQuestions from '@/components/v2/home/FrequentlyAskQuestions'

export default {
  name: 'HomeV2',
  components: {
    WebsiteIntro,
    MobileBanner,
    CompaniesWeWorkWith,
    FrequentlyAskQuestions
  },
  mounted () {
    window.addEventListener('scroll', () => {
      this.reveal(70)
    })
  },
  methods: {
    reveal (slideUpPoint = 100) {
      var slideUpDivs = document.querySelectorAll('.-slide-up');

      for (let i = 0; i < slideUpDivs.length; i++) {
        let windowHeight = window.innerHeight
        let slideUpTop = slideUpDivs[i].getBoundingClientRect().top

        if (slideUpTop < (windowHeight - slideUpPoint)) {
          slideUpDivs[i].classList.add('active')
        }
      }
    }
  }
}
</script>