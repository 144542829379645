<template>
  <div class="container px-md-5 px-xs-2 -contact-us -content-division">
    <div class="row px-3">
      <div class="col-12 shadow d-flex flex-column justify-content-center align-items-start -contact-info p-3 p-md-4 order-2 order-md-1">
        <h1 class="py-3 py-md-4 text-start">Connect with <span class="fw-bolder">whiteStrings</span></h1>
        <span class="py-1 text-start">597 Jenny's Avenue, Maybunga,Pasig City</span>
        <span class="py-1 text-start">Metro Manila, The Philippines</span>
        <span class="py-1 text-start">09680226104</span>
        <span class="py-1 text-start"><a href="mailto:info@whitestrings.io" style="color: inherit !important;">info@whitestrings.io</a></span>
        <div class="py-3">
          <a class="-link-socmed me-1" href="https://www.facebook.com/whitestrings.io/" target="_blank">
            <i class="fa-brands fa-facebook"></i>
          </a>
          <a class="-link-socmed" href="https://www.linkedin.com/company/whitestringsio" target="_blank">
            <i class="fa-brands fa-linkedin"></i>
          </a>
        </div>
      </div>
      <div class="col-12 col-md-7 text-start p-2 p-md-4 -book-form order-1 order-md-2" v-if="false">
        <h2 class="mb-3 fw-bolder">Write Us</h2>
        <div class="row">
          <div class="alert alert-danger" role="alert" v-if="showInputError">
            Please enter all the required details.
          </div>
        </div>
        <form @submit.prevent="submit" autocomplete="off">
          <div class="row mx-0">
            <div class="col-12 col-md-6 mb-3 pe-0 pe-md-1 -input-container position-relative">
              <input type="text" placeholder="Your Name" v-model="form.name" @blur="validateInput($event, form.name)"/>
              <span class="-validation-icon"></span>
            </div>
            <div class="col-12 col-md-6 mb-3 ps-0 ps-md-1 position-relative position-relative">
              <input type="email" placeholder="Your Email" v-model="form.email" @blur="validateEmail($event, form.email)" />
              <span class="-validation-icon"></span>
            </div>
            <div class="col-12 mb-3 position-relative">
              <input type="text" placeholder="Subject" v-model="form.subject" @blur="validateInput($event, form.subject)"/>
              <span class="-validation-icon"></span>
            </div>
            <div class="col-12 mb-3 position-relative">
              <textarea rows="5" placeholder="Your Message" v-model="form.message" @blur="validateInput($event, form.message)"></textarea>
              <span class="-validation-icon"></span>
            </div>
            <div class="col-12 mb-3 d-grid">
              <button type="submit" class="btn btn-secondary btn-block rounded-pill btn-lg">
                Send a Message
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactUs',
  data () {
    return {
      isEmailValid: null,
      form: {
        name: null,
        email: null,
        subject: null,
        message: null
      },
      showInputError: false
    }
  },
  methods: {
    validateEmail(e, email) {
      e.target.classList.remove('-invalid-input', '-valid-input')
      e.target.nextSibling.innerHTML = ''

      if (email) {
        const validate = email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) //eslint-disable-line
        
        if (validate && validate.length > 0) {
            this.isEmailValid = true
            e.target.classList.add('-valid-input')
            e.target.nextSibling.innerHTML = '<i class="fa-regular fa-circle-check text-success"></i>'
            setTimeout(() => { this.isEmailValid = null; e.target.classList.remove('-valid-input'); e.target.nextSibling.innerHTML = ''; }, 2000)
        } else {
            this.isEmailValid = false
            e.target.classList.add('-invalid-input')
            e.target.nextSibling.innerHTML = '<i class="fa-solid fa-triangle-exclamation text-danger"></i>'
        }
      } else {
        e.target.classList.add('-invalid-input')
        e.target.nextSibling.innerHTML = '<i class="fa-solid fa-triangle-exclamation text-danger"></i>'
      }
        
    },
    validateInput(e, value) {
      e.target.classList.remove('-invalid-input', '-valid-input')
      e.target.nextSibling.innerHTML = ''

      if (value) {
        e.target.classList.add('-valid-input')
        e.target.nextSibling.innerHTML = '<i class="fa-regular fa-circle-check text-success"></i>'
        setTimeout(() => { this.isEmailValid = null; e.target.classList.remove('-valid-input'); e.target.nextSibling.innerHTML = ''; }, 2000)
      } else {
        e.target.classList.add('-invalid-input')
        e.target.nextSibling.innerHTML = '<i class="fa-solid fa-triangle-exclamation text-danger"></i>'
      }
    },
    async submit () {
      // const form = JSON.parse(JSON.stringify(this.form))
      this.showInputError = false
      const { name, email, subject, message } = this.form

      if (!name || !email || this.isEmailValid === false || !subject || !message) {
        this.showInputError = true
      } else {
        this.form = {
          name: null,
          email: null,
          subject: null,
          message: null
        }
      }
    }
  },
}
</script>