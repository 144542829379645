import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import axios from "axios";
import VueAxios from "vue-axios";
import BootstrapVue from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";

import VueScreen from "vue-screen";

import "@fortawesome/fontawesome-free/css/all.css";
import '@fortawesome/fontawesome-free/js/all.js'

// Vue.config.productionTip = false

const VueScrollTo = require("vue-scrollto");

Vue.use(BootstrapVue);
Vue.use(VueScreen);
Vue.use(VueAxios, axios);
Vue.use(VueScrollTo);

Vue.component("app", App);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
