<template>
  <div class="row footer justify-content-center align-items-center position-relative -z-index-2">
    <div class="row footer-text">
      <h1>Become the next generation school now</h1>
    </div>
    <div class="row footer-btn" v-if="showButton">
      <div class="col">
        <a class="btn btn-black" href="/book">Contact Us</a>
      </div>
    </div>
    <div class="row">
      <p class="copyright">white<b>Strings</b> all rights reserved <b>{{ new Date().getFullYear() }}</b></p>
    </div>
  </div>
</template>
  
<script>
export default {
  name: 'WsFooter',
  data () {
    return {
      showButton: true
    }
  },
  mounted () {
    if (window.location.pathname == '/book') {
      this.showButton = false
    } else {
      this.showButton = true
    }
  },
  watch: {
    $route (to){
      if (to.path == '/book') {
        this.showButton = false
      } else {
        this.showButton = true
      }
    }
  }
}
</script>
