<template>
  <div>
    <div id="scrollableMobileFeaturesAd" class="-scrollable-mobile-features-ad -hidden-scroll position-relative d-none d-md-block">
      <div class="container">
        <div class="position-sticky" style="top: 5vh; z-index: 1;">
          <div id="mobileAdVideoFeature" class="-mobile-ad-feature">
            <div class="position-relative d-flex justify-content-center align-items-center">
              <div class="position-relative -phone-frame-container">
                <img src="@/assets/graphics/v2/phone_frame.svg">
              </div>
              <div class="position-absolute -phone-screen-content" id="mobileFeaturesAdConnectedVideo">
                <div class="-video-container">
                  <video width="95%" height="auto" autoplay loop controls preload="none" muted>
                    <source src="@/assets/video/mobile-nfc.mp4" type="video/mp4">
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
              <div class="position-absolute -phone-screen-content -hidden" id="mobileFeaturesAdNotificationVideo">
                <div class="-video-container">
                  <video width="95%" height="auto" autoplay loop controls preload="none" muted>
                    <source src="@/assets/video/mobile-soa.mp4" type="video/mp4">
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
              <div class="position-absolute -phone-screen-content -hidden" id="mobileFeaturesAdInformationVideo">
                <div class="-video-container">
                  <video width="95%" height="auto" autoplay loop controls preload="none" muted>
                    <source src="@/assets/video/mobile-bulletin.mp4" type="video/mp4">
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
              <div class="position-absolute -phone-screen-content -hidden" id="mobileFeaturesAdGradesVideo">
                <div class="-video-container">
                  <video width="95%" height="auto" autoplay loop controls preload="none" muted>
                    <source src="@/assets/video/mobile-grades.mp4" type="video/mp4">
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 px-md-5">
          <div class="row align-items-start -mobile-features-ad" id="mobileFeaturesAdConnected">
            <div class="col-6 col-lg-5 d-flex flex-column align-items-start justify-content-center h-100 h-lg-75 text-justify bg-white">
              <h2 class="fw-bolder text-start">Connected in every ways</h2>
              <p class="-text-justify">whiteStrings Mobile is available for different wireless communication systems such as QR Code, RFID and first to integrate NFC ID System for a more dynamic and flexible usage inside the campus.</p>
            </div>
          </div>
          <div class="row align-items-start -mobile-features-ad" id="mobileFeaturesAdNotification">
            <div class="col-6 col-lg-5 d-flex flex-column align-items-start justify-content-center h-100 h-lg-75 text-justify bg-white">
              <h2 class="fw-bolder text-start">Never worry about notifications</h2>
              <p class="-text-justify">Our in-App notifications ensures that your students/parents are updated whenever they need to check their latest SOA.</p>
            </div>
          </div>
          <div class="row align-items-start -mobile-features-ad" id="mobileFeaturesAdInformation">
            <div class="col-6 col-lg-5 d-flex flex-column align-items-start justify-content-center h-100 h-lg-75 text-justify bg-white">
              <h2 class="fw-bolder text-start">Never miss any information</h2>
              <p class="-text-justify">Your campus school feed will provide you the latest news and announcement from the schools' admin</p>
            </div>
          </div>
          <div class="row align-items-start -mobile-features-ad" id="mobileFeaturesAdGrades">
            <div class="col-6 col-lg-5 d-flex flex-column align-items-start justify-content-center h-100 h-lg-75 text-justify bg-white">
              <h2 class="fw-bolder text-start">Real-time Grades</h2>
              <p class="-text-justify">Stay effortlessly connected to your student's academic progress, receive instant updates on grades, and empower your learning journey with real-time insights—all within a user-friendly interface designed for your students' success.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container px-md-5 px-4 ">
      <div class="d-block d-md-none">
        <div class="row my-5">
          <div class="col-12 d-flex flex-column align-items-center justify-content-center">
            <div class="-mobile-video-container">
              <div class="position-relative row justify-content-center align-items-center">
                <div class="position-relative -m-phone-frame-container">
                  <img src="@/assets/graphics/v2/phone_frame.svg">
                </div>
                <div class="position-absolute -m-phone-screen-content">
                  <div class="-m-video-container">
                    <video width="95%" height="auto" autoplay loop preload="none" muted>
                      <source src="@/assets/video/mobile-nfc.mp4" type="video/mp4">
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>
              </div>
            </div>
            <div class="-mobile-video-description my-4">
              <h4 class="fw-bolder">Connected in every ways</h4>
              <p class="-text-justify">whiteStrings Mobile is available for different wireless communication systems such as QR Code, RFID and first to integrate NFC ID System for a more dynamic and flexible usage inside the campus.</p>
            </div>
          </div>
          <div class="col-12 d-flex flex-column align-items-center justify-content-center">
            <div class="-mobile-video-container">
              <div class="position-relative row justify-content-center align-items-center">
                <div class="position-relative -m-phone-frame-container">
                  <img src="@/assets/graphics/v2/phone_frame.svg">
                </div>
                <div class="position-absolute -m-phone-screen-content">
                  <div class="-m-video-container">
                    <video width="95%" height="auto" autoplay loop preload="none" muted>
                      <source src="@/assets/video/mobile-soa.mp4" type="video/mp4">
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>
              </div>
            </div>
            <div class="-mobile-video-description my-4">
              <h4 class="fw-bolder">Never worry about notifications</h4>
              <p class="-text-justify">Our in-App notifications ensures that your students/parents are updated whenever they need to check their latest SOA.</p>
            </div>
          </div>
          <div class="col-12 d-flex flex-column align-items-center justify-content-center">
            <div class="-mobile-video-container">
              <div class="position-relative row justify-content-center align-items-center">
                <div class="position-relative -m-phone-frame-container">
                  <img src="@/assets/graphics/v2/phone_frame.svg">
                </div>
                <div class="position-absolute -m-phone-screen-content">
                  <div class="-m-video-container">
                    <video width="95%" height="auto" autoplay loop preload="none" muted>
                      <source src="@/assets/video/mobile-bulletin.mp4" type="video/mp4">
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>
              </div>
            </div>
            <div class="-mobile-video-description my-4">
              <h4 class="fw-bolder">Never miss any information</h4>
              <p class="-text-justify">Your campus school feed will provide you the latest news and announcement from the schools' admin</p>
            </div>
          </div>
          <div class="col-12 d-flex flex-column align-items-center justify-content-center">
            <div class="-mobile-video-container">
              <div class="position-relative row justify-content-center align-items-center">
                <div class="position-relative -m-phone-frame-container">
                  <img src="@/assets/graphics/v2/phone_frame.svg">
                </div>
                <div class="position-absolute -m-phone-screen-content">
                  <div class="-m-video-container">
                    <video width="95%" height="auto" autoplay loop preload="none" muted>
                      <source src="@/assets/video/mobile-grades.mp4" type="video/mp4">
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>
              </div>
            </div>
            <div class="-mobile-video-description my-4">
              <h4 class="fw-bolder">Real-time Grades</h4>
              <p class="-text-justify">Stay effortlessly connected to your student's academic progress, receive instant updates on grades, and empower your learning journey with real-time insights—all within a user-friendly interface designed for your students' success.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScrollableMobileFeatures',
  data () {
    return {
      percentage1: 0,
      percentage2: 0,
      percentage3: 0,
      percentage4: 0,
      mobilePercentage: 0
    }
  },
  mounted () {
    const adContainer = document.getElementById('scrollableMobileFeaturesAd')
    // const adVideoContainer =  document.getElementById('mobileAdVideoFeature')
    const element1 = document.getElementById('mobileFeaturesAdConnected')
    const element2 = document.getElementById('mobileFeaturesAdNotification')
    const element3 = document.getElementById('mobileFeaturesAdInformation')
    const element4 = document.getElementById('mobileFeaturesAdGrades')
    const neededTopOffset = window.innerHeight / 6
    // const neededBottomOffset = window.innerHeight - (window.innerHeight / 7)
    // const mobileAdVideoFeature = document.getElementById('mobileAdVideoFeature')

    adContainer.addEventListener('scroll', () => {
      const containerHeight = adContainer.clientHeight;
      const scrollTop = adContainer.scrollTop;

      const elementOffsetTop1 = element1.offsetTop;
      const elementHeight1 = element1.offsetHeight;
      const distance1 = (scrollTop + containerHeight) - elementOffsetTop1;
      this.percentage1 = Math.round(distance1 / ((containerHeight + elementHeight1) / 100));

      const elementOffsetTop2 = element2.offsetTop;
      const elementHeight2 = element2.offsetHeight;
      const distance2 = (scrollTop + containerHeight) - elementOffsetTop2;
      this.percentage2 = Math.round(distance2 / ((containerHeight + elementHeight2) / 100));

      const elementOffsetTop3 = element3.offsetTop;
      const elementHeight3 = element3.offsetHeight;
      const distance3 = (scrollTop + containerHeight) - elementOffsetTop3;
      this.percentage3 = Math.round(distance3 / ((containerHeight + elementHeight3) / 100));

      const elementOffsetTop4 = element4.offsetTop;
      const elementHeight4 = element4.offsetHeight;
      const distance4 = (scrollTop + containerHeight) - elementOffsetTop4;
      this.percentage4 = Math.round(distance4 / ((containerHeight + elementHeight4) / 100));
    });

    window.addEventListener('scroll', () => {
      const currentElTopOffset = adContainer.getBoundingClientRect().top
      // const currentElBottomOffset = adVideoContainer.getBoundingClientRect().bottom

      if (currentElTopOffset <= neededTopOffset && currentElTopOffset > 0) {
        adContainer.style.overflowY = 'auto'
      } else {
        adContainer.style.overflowY = 'hidden'
      }
    })
  },
  watch: {
    percentage1 (newValue, prevValue) {
      if (newValue > 0 && prevValue > 0) {
        let opacity = 1

        if (newValue > 55 && newValue <= 60) {
          opacity = 0.2
        } else if (newValue > 60 && newValue <= 70) {
          opacity = 0.09
        } else if (newValue > 70) {
          opacity = 0.001
        }

        document.getElementById('mobileFeaturesAdConnected').style.opacity = opacity
        document.getElementById('mobileFeaturesAdConnectedVideo').style.opacity = opacity
      }
    },
    percentage2 (newValue, prevValue) {
      if (newValue > 0 && prevValue > 0) {
        let opacity = 1;

        if (newValue > 0 && newValue <= 20) {
            opacity = 0.001
          } else if (newValue > 20 && newValue <= 40) {
            opacity = 0.09
          } else if (newValue > 40 && newValue <= 55) {
            opacity = 1;
          } else if (newValue > 55 && newValue <= 60) {
            opacity = 0.2
          } else if (newValue > 60 && newValue <= 70) {
            opacity = 0.09
          } else if (newValue > 70) {
            opacity = 0.001
          }

        document.getElementById('mobileFeaturesAdNotification').style.opacity = opacity
        document.getElementById('mobileFeaturesAdNotificationVideo').style.opacity = opacity
      }
    },
    percentage3 (newValue, prevValue) {
      if (newValue > 0 && prevValue > 0) {
        let opacity = 1;

        if (newValue > 0 && newValue <= 20) {
            opacity = 0.001
          } else if (newValue > 20 && newValue <= 40) {
            opacity = 0.09
          } else if (newValue > 40 && newValue <= 55) {
            opacity = 1;
          } else if (newValue > 55 && newValue <= 60) {
            opacity = 0.2
          } else if (newValue > 60 && newValue <= 70) {
            opacity = 0.09
          } else if (newValue > 70) {
            opacity = 0.001
          }

        document.getElementById('mobileFeaturesAdInformation').style.opacity = opacity
        document.getElementById('mobileFeaturesAdInformationVideo').style.opacity = opacity
      }
    },
    percentage4 (newValue, prevValue) {
      if (newValue > 0 && prevValue > 0) {
        let opacity = 1;

        if (newValue > 0 && newValue <= 20) {
            opacity = 0.001
          } else if (newValue > 20 && newValue <= 40) {
            opacity = 0.09
          } else if (newValue > 40 && newValue <= 55) {
            opacity = 1;
          } else if (newValue > 55 && newValue <= 60) {
            opacity = 0.2
          } else if (newValue > 60 && newValue <= 70) {
            opacity = 0.09
          } else if (newValue > 70) {
            opacity = 0.001
          }

        document.getElementById('mobileFeaturesAdGrades').style.opacity = opacity
        document.getElementById('mobileFeaturesAdGradesVideo').style.opacity = opacity
      }
    }
  }
}
</script>

<style>

</style>