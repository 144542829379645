<template>
  <div class="container pt-5">
    <TeamWs />
    <CoreValues />
    <MeetTheTeam />
  </div>
</template>

<script>
import TeamWs from '@/components/v2/about/TeamWs'
import CoreValues from '@/components/v2/about/CoreValues'
import MeetTheTeam from '@/components/v2/about/MeetTheTeam'

export default {
  name: 'HomeV2',
  components: {
    TeamWs,
    CoreValues,
    MeetTheTeam
  },
  mounted () {
    window.addEventListener('scroll', () => {
      this.reveal(80)
    })
  },
  methods: {
    reveal (slideUpPoint = 100) {
      var slideUpDivs = document.querySelectorAll('.-slide-up');

      for (let i = 0; i < slideUpDivs.length; i++) {
        let windowHeight = window.innerHeight
        let slideUpTop = slideUpDivs[i].getBoundingClientRect().top

        if (slideUpTop < (windowHeight - slideUpPoint)) {
          slideUpDivs[i].classList.add('active')
        }
      }
    }
  }
}
</script>