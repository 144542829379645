<template>
  <div class="container px-md-5 px-xs-0 py-5">
    <div class="row justify-content-center align-items-center py-5">
      <h1 class="-font-now">Companies we work with</h1>
    </div>

    <div class="row flex-row justify-content-center py">
      <div class="slider">
        <div class="slide-track">
          <div class="slide -clients-logo">
            <img src="@/assets/graphics/v2/school_logo/almlc.png" alt="almlc" />
          </div>
          <div class="slide -clients-logo">
            <img src="@/assets/graphics/v2/school_logo/bu.png" alt="bu" />
          </div>
          <div class="slide -clients-logo">
            <img src="@/assets/graphics/v2/school_logo/cdnms_binan.png" alt="cdnms_binan" />
          </div>
          <div class="slide -clients-logo">
            <img src="@/assets/graphics/v2/school_logo/cdnms_calamba.png" alt="cdnms_calamba" />
          </div>
          <div class="slide -clients-logo">
            <img src="@/assets/graphics/v2/school_logo/cdnms_hs.png" alt="cdnms_hs" />
          </div>
          <div class="slide -clients-logo">
            <img src="@/assets/graphics/v2/school_logo/cdnms_ilagan.png" alt="cdnms_ilagan" />
          </div>
          <div class="slide -clients-logo">
            <img src="@/assets/graphics/v2/school_logo/cdnms_roxas.png" alt="cdnms_roxas" />
          </div>
          <div class="slide -clients-logo">
            <img src="@/assets/graphics/v2/school_logo/olpmc.png" alt="olpmc" />
          </div>
          <div class="slide -clients-logo">
            <img src="@/assets/graphics/v2/school_logo/sclc.png" alt="sclc" />
          </div>
          <div class="slide -clients-logo">
            <img src="@/assets/graphics/v2/school_logo/sjcb.png" alt="sjcb" />
          </div>
          <div class="slide -clients-logo">
            <img src="@/assets/graphics/v2/school_logo/tlcnbs.png" alt="tlcnbs" />
          </div>

          <!--- 
            Duplicate: For continuous sliding 
            If new slide will be added, change the value of "--slider-count" in main.css
          -->
          <div class="slide -clients-logo">
            <img src="@/assets/graphics/v2/school_logo/almlc.png" alt="almlc" />
          </div>
          <div class="slide -clients-logo">
            <img src="@/assets/graphics/v2/school_logo/bu.png" alt="bu" />
          </div>
          <div class="slide -clients-logo">
            <img src="@/assets/graphics/v2/school_logo/cdnms_binan.png" alt="cdnms_binan" />
          </div>
          <div class="slide -clients-logo">
            <img src="@/assets/graphics/v2/school_logo/cdnms_calamba.png" alt="cdnms_calamba" />
          </div>
          <div class="slide -clients-logo">
            <img src="@/assets/graphics/v2/school_logo/cdnms_hs.png" alt="cdnms_hs" />
          </div>
          <div class="slide -clients-logo">
            <img src="@/assets/graphics/v2/school_logo/cdnms_ilagan.png" alt="cdnms_ilagan" />
          </div>
          <div class="slide -clients-logo">
            <img src="@/assets/graphics/v2/school_logo/cdnms_roxas.png" alt="cdnms_roxas" />
          </div>
          <div class="slide -clients-logo">
            <img src="@/assets/graphics/v2/school_logo/olpmc.png" alt="olpmc" />
          </div>
          <div class="slide -clients-logo">
            <img src="@/assets/graphics/v2/school_logo/sclc.png" alt="sclc" />
          </div>
          <div class="slide -clients-logo">
            <img src="@/assets/graphics/v2/school_logo/sjcb.png" alt="sjcb" />
          </div>
          <div class="slide -clients-logo">
            <img src="@/assets/graphics/v2/school_logo/tlcnbs.png" alt="tlcnbs" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompaniesWeWorkWith',
  components: {
    
  }
}
</script>
