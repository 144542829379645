<template>
  <div class="container pt-5">
    <PlatformFeatures />
    <WsPay />
  </div>
</template>

<script>
import PlatformFeatures from '@/components/v2/featues/PlatformFeatures'
import WsPay from '@/components/v2/featues/WsPay'

export default {
  name: 'FeaturesV2',
  components: {
    PlatformFeatures,
    WsPay
  },
  mounted () {
    window.addEventListener('scroll', () => {
      this.reveal(80)
    })
  },
  methods: {
    reveal (slideUpPoint = 100) {
      var slideUpDivs = document.querySelectorAll('.-slide-up');

      for (let i = 0; i < slideUpDivs.length; i++) {
        let windowHeight = window.innerHeight
        let slideUpTop = slideUpDivs[i].getBoundingClientRect().top

        if (slideUpTop < (windowHeight - slideUpPoint)) {
          slideUpDivs[i].classList.add('active')
        }
      }
    }
  }
}
</script>