<template>
  <div class="container px-md-5 px-xs-0">
    <div id="fadingBanner" class="col -top-0" style="position: sticky; z-index: 1; opacity: 1; transition: opacity 3s ease-out">
      <div class="row justify-content-center pt-5 col-md-12">
        <h1 class="mb-2 -font-now -school-changing-text">Schools <span :class="'typewriter-text ' + currentWordColor" id="typewriterText"></span><span :class="'-typewriter-cursor ' + currentWordColor">|</span></h1>
        <p class="-website-intro-description">A new generation of <br class="d-md-none">automation and artificial intelligence</p>
      </div>

      <div class="row -animating-dashboard-image" style="padding-bottom: 100px;">
        <div class="col-md-12">
          <img src="@/assets/graphics/v2/system_platform.svg" id="imgSystemPlatform" ref="imgSystemPlatform" style="height:auto; width:95%; transition: width 2s ease-out;"/>
        </div>
      </div>
    </div>

    <div class="col bg-white position-relative px-3" style="z-index: 2;">
      <div class="row -mb-7 -section-hyper-connected px-md-2 position-relative -slide-up -z-index-2">
        <div class="col-md-6 col-sm-12 d-flex flex-column justify-content-center align-items-center align-items-md-start">
          <h2 class="fw-bolder text-center text-md-start">A Hyperconnected Environment</h2>
          <p class="text-center text-md-start">white<b>Strings</b> brings you all the features you need in one platform. From Enrollment to Graduation, an end-to-end school automation platform provides seamless process in a hyperconnected environment.</p>
        </div>
        <div class="col-md-6 col-sm-12">
          <div class="row justify-content-center align-items-center">
            <img src="@/assets/graphics/v2/hyper_connected.svg" style="height:300px; width:auto;">
          </div>
        </div>
      </div>

      <div class="row -mb-7 -doing-work px-md-2 position-relative -slide-up -z-index-2">
        <div class="col-md-6 col-sm-12 -section-doing-work-image">
          <div class="row justify-content-center align-items-center">
            <img src="@/assets/graphics/v2/doing_work.svg" style="height:300px; width:auto;">
          </div>
        </div>
        <div class="col-md-6 col-sm-12 -section-doing-work-text d-flex flex-column justify-content-center align-items-center align-items-md-end">
          <h2 class="fw-bolder text-center text-md-end">Doing most of the work for you</h2>
          <p class="text-center text-md-end">Automate most of your operations, eliminating tedious work that is not relevant to each role, whiteStrings helps you do less work for more results.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WebsiteIntro',
  data () {
    return {
      wordIndex: 0,
      words: ["Reimagined", "Reengineered", "Automated", "Empowered"],
      wordColors: ["-green", "-yellow", "-blue", "-red"],
      currentLoopText: "Reimagined",
      currentWordColor: "-green",
      sleepTime: 100,

      percentage: 0,
    }
  },
  methods: {
    sleep : function (ms) {
      return new Promise((resolve) => setTimeout(resolve, ms))
    },
    typewriterEffect: async function () {
      const el = document.getElementById("typewriterText")

      if (el) {
        let currentLoopText = this.words[this.wordIndex]

        for (let i = 0; i < currentLoopText.length; i++) {
          el.innerHTML = currentLoopText.substring(0, i + 1)

          await this.sleep(this.sleepTime)
        }

        await this.sleep(this.sleepTime * 10)

        for (let i = currentLoopText.length; i > 0; i--) {
          el.innerHTML = currentLoopText.substring(0, i - 1)

          await this.sleep(this.sleepTime)
        }

        await this.sleep(this.sleepTime * 5)

        if (this.wordIndex == this.words.length - 1) {
          this.wordIndex = 0
        } else {
          this.wordIndex++
        }

        this.currentWordColor = this.wordColors[this.wordIndex]
        this.typewriterEffect()
      }
    },
  },
  mounted () {
    if (window.innerWidth <= 480) {
      document.getElementById('fadingBanner').style.position = 'static'
    } else {
      document.getElementById('fadingBanner').style.position = 'sticky'
    }

    this.typewriterEffect();

    const element = document.getElementById('imgSystemPlatform');

    window.addEventListener('scroll', () => {
      const viewportHeight = window.innerHeight;
      const scrollTop = window.scrollY;
      const elementOffsetTop = element.offsetTop;
      const elementHeight = element.offsetHeight;

      if (window.innerWidth > 480) {
        const distance = (scrollTop + viewportHeight) - elementOffsetTop;
        this.percentage = Math.round(distance / ((viewportHeight + elementHeight) / 100));
      }
    });
  },
  watch: {
    percentage(newValue, prevValue) {
      // Scroll Down
      if (newValue > prevValue) {
        if (newValue > 40 && newValue <= 50) {
          document.getElementById('imgSystemPlatform').style.width = '80%';
        } else if (newValue > 50 && newValue <= 60) {
          document.getElementById('imgSystemPlatform').style.width = '70%';
        } else if (newValue > 60) {
          document.getElementById('imgSystemPlatform').style.width = '60%';
        }

        if (newValue > 50 && newValue <= 60) {
          document.getElementById('fadingBanner').style.opacity = 0.2
        } else if (newValue > 60 && newValue <= 70) {
          document.getElementById('fadingBanner').style.opacity = 0.09
        } else if (newValue > 70) {
          document.getElementById('fadingBanner').style.opacity = 0.07
        }

        if (newValue > 110) {
          document.getElementById('fadingBanner').style.position = 'relative'
        } else {
          document.getElementById('fadingBanner').style.position = 'sticky';
        }
      }

      // Scroll Up
      else {
        if (newValue > 80) {
          document.getElementById('imgSystemPlatform').style.width = '65%';
        } else if (newValue > 70) {
          document.getElementById('imgSystemPlatform').style.width = '74%';
        } else if (newValue > 60) {
          document.getElementById('imgSystemPlatform').style.width = '80%';
        } else {
          document.getElementById('imgSystemPlatform').style.width = '95%';
        }

        if (newValue > 80) {
          document.getElementById('fadingBanner').style.opacity = 0.08
        } if (newValue > 70) {
          document.getElementById('fadingBanner').style.opacity = 0.1
        } else if (newValue > 65) {
          document.getElementById('fadingBanner').style.opacity = 0.3
        } else {
          document.getElementById('fadingBanner').style.opacity = 1
        }

        if (newValue > 110) {
          document.getElementById('fadingBanner').style.position = 'relative'
        } else {
          document.getElementById('fadingBanner').style.position = 'sticky';
        }
      }
    }
  }
}
</script>