<template>
  <div class="px-md-5 px-xs-0 -content-division -meet-the-team position-relative -slide-up -z-index-2">
    <div class="row flex-content-center align-items-center">
      <h1 class="fw-bolder mb-5">Meet the Team</h1>
    </div>
    <div class="row">
      <div class="col-6 col-sm-4 col-md-3 mb-3" v-for="(member, i) in team" :key="i">
        <img :src="member.image" class="-team-member-image">
        <h5 class="fw-bolder">{{ member.name }}</h5>
        <p class="small">{{ member.position }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MeetTheTeam',
  data () {
    return {
      'team': [
        {
          'name': 'Team Member 1',
          'position': 'Position 1',
          'image': require('@/assets/graphics/v2/school_centered_app.svg')
        },
        {
          'name': 'Team Member 2',
          'position': 'Position 2',
          'image': require('@/assets/graphics/v2/school_centered_app.svg')
        },
        {
          'name': 'Team Member 3',
          'position': 'Position 3',
          'image': require('@/assets/graphics/v2/school_centered_app.svg')
        },
        {
          'name': 'Team Member 4',
          'position': 'Position 4',
          'image': require('@/assets/graphics/v2/school_centered_app.svg')
        },
        {
          'name': 'Team Member 5',
          'position': 'Position 5',
          'image': require('@/assets/graphics/v2/school_centered_app.svg')
        },
        {
          'name': 'Team Member 6',
          'position': 'Position 6',
          'image': require('@/assets/graphics/v2/school_centered_app.svg')
        }
      ]
    }
  }
}
</script>