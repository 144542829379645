<template>
  <div class="px-md-5" :style="bannerCss">
    <div class="container">
      <div class="d-flex flex-column justify-content-start align-items-start position-relative">
        <img src="@/assets/graphics/v2/handy_phone.svg" class="-img-mobile-banner"/>

        <div class="d-flex flex-column align-items-start position-absolute text-white -mobile-banner-text">
          <span class="-txt-ws">white<b>Strings Mobile</b></span>
          <span class="-txt-first-super-app">The first super app for schools.</span>
          <a class="-txt-link-mobile" target="_blank" href="/mobile">Learn more</a>
        </div>
      </div>
    </div>
      
  </div>
</template>

<script>
export default {
  name: 'MobileBanner',
  data() {
    return {
      bannerCss: {
        background: `linear-gradient(
          rgba(0, 0, 0, 0.4), 
          rgba(0, 0, 0, 0.4)
        ), url(${require('@/assets/graphics/v2/mobile_banner_bg.svg')})`,
        backgroundRepeat: `no-repeat`,
        backgroundSize: `cover`,
      }
    }
  }
}
</script>