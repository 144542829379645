<template>
  <div id="mobileAppLink" class="container px-md-5 px-xs-0 -mobile-app-link py-md-5">
    <div class="d-flex align-items-center justify-content-center py-5 py-md-3">
      <span class="me-0 me-md-5">
        <a class="btn -btn-app-link p-0" href="https://www.apple.com/ph/app-store/" target="_blank">
          <img src="@/assets/graphics/v2/btn_app_store.svg" />
        </a>
      </span>
      <span>
        <a class="btn -btn-app-link p-0" href="https://play.google.com/store/games?hl=en&gl=US" target="_blank">
          <img src="@/assets/graphics/v2/btn_gplay.svg" />
        </a>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppLink'
}
</script>