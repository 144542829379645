<template>
  <div class="px-md-5 px-xs-0 -core-values -content-division position-relative -slide-up -z-index-2">
    <div class="row">
      <div class="col d-flex flex-column flex-content-center align-items-center">
        <h1 class="fw-bolder mb-5">Core Values</h1>

        <h3 class="fw-bold">We Believe in God</h3>
        <h3 class="fw-bold">We Do the Right Thing</h3>
        <h3 class="fw-bold">We Do What's Right</h3>
        <h3 class="fw-bold">We Believe in Ourselves</h3>
        <h3 class="fw-bold">We Make Things Happen</h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CoreValues'
}
</script>