<template>
  <div class="px-md-5 px-xs-0 -ws-pay pt-md-5 pt-0 bg-white position-relative -slide-up -z-index-2">
    <div class="row justify-content-center py-5">
      Best with Chrome, Safari and Microsoft Edge
    </div>

    <div class="col-12 d-flex flex-column align-items-center">
      <label class="d-block -lbl-ws-pay">white<b>Strings Pay</b></label>
      <p>Pay easy online anytime, anywhere.</p>
    </div>

    <div class="row justify-content-center py-5">
      <div class="col-md-5 -section-cashless-description">
        <label class="d-block fw-bold">Cashless payment for your school</label>
        <p class="-text-justify px-5 px-md-0">
          whiteStrings Pay offers over-the-counter, bank transfers creditcard payments without a need to manage transactions separately. Our powerful cashiering system can validate  payments regardless of payment method used. Our cash-less payment is powered by UBx, a payment gateway solution of UnionBank Philippines. 
        </p>
      </div>
      <div class="col-md-5 -section-ubx-logo mb-5 mb-md-0">
        <div class="row justify-content-center align-items-center">
          <img src="@/assets/graphics/v2/ubx_banner_logo.svg" style="height:auto; width:60%;">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WsPay'
}
</script>