<template>
    <div class="row main-nav" id="main-nav">
      <nav class="navbar navbar-expand-lg navbar-light left-menu">
        <a class="navbar-brand" id="header-icon" href="/">
          <wsLogo />
        </a>
        <button class="navbar-toggler" type="button" aria-label="Toggle navigation" @click="showMenu(true)">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
            <li class="nav-item nav-common">
              <router-link @click.native="scrollToTop" to="/" class="nav-link" exact>Home</router-link>
            </li>
            <li class="nav-item nav-common">
              <router-link @click.native="scrollToTop" to="/features" class="nav-link" exact>Features</router-link>
            </li>
            <li class="nav-item nav-common">
              <router-link @click.native="scrollToTop" to="/mobile" class="nav-link" exact>Mobile App</router-link>
            </li>
            <li class="nav-item nav-common">
              <router-link @click.native="scrollToTop" to="/about" class="nav-link" exact>About Us</router-link>
            </li>
            <li class="nav-item nav-common">
              <router-link @click.native="scrollToTop" to="/book" class="nav-link" exact>Contact Us</router-link>
            </li>
          </ul>
        </div>
      </nav>
  
      <div id="myNav" class="overlay" v-if="mobileMenu">
        <a class="closebtn" @click="showMenu(false)" >&times;</a>
        <div class="overlay-content">
          <a href="/">Home</a>
          <a href="/features">Features</a>
          <a href="/mobile">Mobile App</a>
          <a href="/about">About Us</a>
          <a href="/book">Contact Us</a>
        </div>
      </div>
    </div>
  </template>
  
<script>
import wsLogo from '@/components/svgs/wsLogo'
export default {
  name: 'WsHeader',
  components: {
    wsLogo
  },
  data() {
    return {
      mobileMenu: false,
    }
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll () {
      if(window.scrollY > 60){
          document.getElementById("main-nav").classList.add("nav-fixed");
      }else{
          document.getElementById("main-nav").classList.remove("nav-fixed");
      }
    },
    showMenu(setStatus) {
      this.mobileMenu = setStatus;

      if (setStatus) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    },
    scrollToTop() {
      window.scrollTo(0,0);
    }
  },
}
</script>