<template>
  <div id="app">
    <WsHeader/>
      <router-view/>
    <WsFooter/>
  </div>
</template>


<script>
// @ is an alias to /src
import WsHeader from '@/components/v2/WsHeader.vue'
import WsFooter from '@/components/v2/WsFooter.vue'

export default {
  name: 'MainApp',
  components: {
    WsHeader,
    WsFooter,
  }
}
</script>

<style>
@import './assets/styles/main.css';
</style>