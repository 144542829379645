<template>
  <div class="px-md-5 px-xs-0 -mobile-app-banner"  :style="bannerCss">
    <div class="container">
      <div class="d-flex flex-column justify-content-start align-items-start">
        <div class="d-flex flex-column position-absolute text-white -mobile-ad-banner-text ">
          <span class="-txt-mobile-ad-title fw-bolder">A new generation app made for Schools</span>
          <span class="-txt-mobile-ad-description">More than just homeworks and class announcements.</span>

          <div class="justify-content-center w-75 -mobile-ad-link-group">
            <span class="me-0 me-md-5">
              <a class="btn -btn-app-link p-0" href="https://www.apple.com/ph/app-store/" target="_blank">
                <img src="@/assets/graphics/v2/btn_app_store.svg" />
              </a>
            </span>
            <span>
              <a class="btn -btn-app-link p-0" href="https://play.google.com/store/games?hl=en&gl=US" target="_blank">
                <img src="@/assets/graphics/v2/btn_gplay.svg" />
              </a>
            </span>
          </div>

          <span class="-txt-mobile-ad-description d-none d-md-block">A new way of connecting to your students</span>
        </div>
        <img src="@/assets/graphics/v2/mobile_ad_banner.svg" class="-img-mobile-ad-banner"/>
      </div>
    </div>
      
  </div>
</template>

<script>
export default {
  name: 'MobileAppBanner',
  data() {
    return {
      bannerCss: {
        background: `linear-gradient(
          to right,
          rgba(0, 0, 0, 0.8), 
          rgba(0, 0, 0, 0.1)
        ), url(${require('@/assets/graphics/v2/mobile_banner_bg.svg')})`,
        backgroundRepeat: `no-repeat`,
        backgroundSize: `cover`,
      }
    }
  }
}
</script>